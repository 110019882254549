import React, { useState } from "react";
import { Helmet } from "react-helmet";

import PageHeader from "../components/pageHeader";
import Layout from "../components/layout";
import SEO from "../components/seo";
import CircularProgress from "@material-ui/core/CircularProgress";

const ApplicationForm = ({ location }) => {
  const [loading, setLoading] = useState(true);

  return (
    <Layout pageTitle="application-form">
      <Helmet>
        <script src="https://services.cognitoforms.com/scripts/embed.js"></script>
      </Helmet>
      <SEO pageTitle="Αίτηση Εγγραφής" />
      <PageHeader
        pageTitle="ΑΙΤΗΣΗ ΕΓΓΡΑΦΗΣ"
        pageDescription="Για διευκρινίσεις επικοινωνήστε με τη γραμματεία στο cas.secretariat@gmail.com, ή στο τηλ. 25002131."
        page="Αίτηση Εγγραφής"
        location={location}
      />
      <div className="application-form-wrapper">
        {loading && <CircularProgress disableShrink />}
        <iframe
          title="application-form"
          id="cognito-iframe"
          onLoad={() => setLoading(false)}
          src="https://services.cognitoforms.com/f/bJPjV7nVCEyycxTGbf1Xjg?id=1"
          style={{
            visibility: loading ? "hidden" : "visible",
            position: "relative",
            width: 1,
            minWidth: "100%",
            width: "100%",
          }}
          scrolling="yes"
          seamless="seamless"
          height="974"
          width="100%"
        ></iframe>
      </div>
    </Layout>
  );
};

export default ApplicationForm;
